@tailwind base;
@tailwind components;
@tailwind utilities;

.text-alert {
  @apply text-sm font-medium text-red-600;
}

.button {
  @apply p-2  bg-secondary-500 hover:bg-secondary-600 shadow-md text-white;
}

.input {
  @apply p-3 text-lg shadow-sm focus:ring-tertiary focus:border-tertiary block w-full sm:text-sm border border-gray-300;
}

.form-label {
  @apply text-sm text-gray-700;
}

.disabled {
  @apply text-gray-400;
}

.select {
  @apply mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-tertiary focus:border-tertiary sm:text-sm;
}
